<template>
  <b-container> </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 
</style>